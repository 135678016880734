@responsive {
  .stack-xs > * + * {
    @apply mt-4;
  }

  .stack-sm > * + * {
    @apply mt-7;
  }

  .stack-md > * + * {
    @apply mt-10;
  }

  .stack-xl > * + * {
    margin-block-start: 5.125rem;
  }

  .stack-2xl > * + * {
    margin-block-start: 8.75rem;
  }

  .stack-3xl > * + * {
    @apply mt-64;
  }
}
