.counter-reset {
  counter-reset: counter var(--init-count, 0);
}

.counter-decrement {
  counter-increment: counter -1;
}

.counter-text {
  &::after {
    content: counter(counter, decimal-leading-zero);

    @nest .project & {
      display: none;
    }
  }
}
