.text-fix {
  display: block;
  transform: translateY(0.2em);

  &::before {
    display: block;
    height: 0;
    content: '';
    margin-block-start: -0.5em;
  }
}
