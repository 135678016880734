<script lang="ts">
import { VNode, RenderContext, CreateElement } from 'vue';

export default {
  name: 'TransitionExpand',

  functional: true,

  render(
    createElement: CreateElement,
    context: RenderContext,
  ): VNode | VNode[] {
    const data = {
      props: {
        name: 'expand',
        mode: context.props.mode,
        duration: context.props.duration,
        enabled: context.props.enabled,
      },
      on: {
        enter(element: any): void {
          const { position } = getComputedStyle(element);
          /* eslint-disable no-param-reassign */
          element.style.position = 'absolute';
          element.style.visibility = 'hidden';
          element.style.height = 'auto';
          /* eslint-enable */
          const { height } = getComputedStyle(element);
          /* eslint-disable no-param-reassign */
          element.style.position = position;
          element.style.visibility = 'visible';
          element.style.height = '0';
          /* eslint-enable */

          // force dom re-render,
          // unless flip may happen
          /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
          getComputedStyle(element).height;

          requestAnimationFrame(() => {
            /* eslint-disable-next-line no-param-reassign */
            element.style.height = height;
          });
        },

        afterEnter(element: any): void {
          /* eslint-disable-next-line no-param-reassign */
          element.style.height = 'auto';
        },

        leave(element: any): void {
          const { height } = getComputedStyle(element);
          /* eslint-disable-next-line no-param-reassign */
          element.style.height = height;

          // force dom re-render,
          // unless flip may happen
          /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
          getComputedStyle(element).height;

          requestAnimationFrame(() => {
            /* eslint-disable-next-line no-param-reassign */
            element.style.height = '0';
          });
        },
      },
    };

    if (data.props.enabled) {
      return createElement('transition', data, context.children);
    }

    return context.children;
  },
};
</script>

<style scoped>
* {
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translateZ(0);
  will-change: height;
}
</style>

<style>
.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  transition: height 0.25s ease-in-out;
}
.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
