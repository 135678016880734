#nprogress {
  position: absolute;
  inset-block-end: 4px;
  pointer-events: none;

  & .bar {
    position: fixed;
    z-index: 1031;
    width: 100%;
    height: 4px;
    background: currentColor;
    inset-start: 0;
  }
}
