/**
 * main.css
 *
 * The entry point for the css.
 *
 */

/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */

@import 'tailwindcss/base';

@import './elements/html.css';
@import './elements/body.css';
@import './elements/p.css';
@import './elements/hr.css';
@import './elements/abbr.css';
@import './elements/img.css';
@import './elements/button.css';

/**
 * This injects any component classes registered by plugins.
 *
 */

@import 'tailwindcss/components';

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */

@import 'tailwindcss/utilities';

@import './generic/fullscreen.css';

@import './utilities/flexbox-grid.css';
@import './utilities/stack.css';
@import './utilities/text-indent.css';
@import './utilities/columns.css';
@import './utilities/text-fix.css';
@import './utilities/dark-mode.css';
@import './utilities/opacity.css';
@import './utilities/counter.css';

@import './components/photoswipe.css';
@import './components/nprogress.css';
