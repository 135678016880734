.pswp__ui {
  mix-blend-mode: difference;
}

.pswp__caption__center {
  @apply text-center text-base text-white !important;
}

.pswp__top-bar,
.pswp__caption {
  background-color: transparent !important;
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  &::before {
    @apply text-white text-base !important;
    content: '' !important;
  }
}

.pswp__button--arrow--right {
  &::before {
    background: url('data:image/svg+xml;utf-8,<svg width="100%" height="100%" viewBox="0 0 27 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M15.04,22.9l-1.73,-1.8l8.42,-8.35l-21.73,0l0,-2.6l21.74,0l-8.42,-8.35l1.73,-1.8l11.47,11.45l-11.48,11.45Z" style="fill:%23fff;fill-rule:nonzero;" /></svg>')
      center / 18px 18px no-repeat !important;
  }
}

.pswp__button--arrow--left {
  &::before {
    background: url('data:image/svg+xml;utf-8,<svg width="100%" height="100%" viewBox="0 0 27 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M11.48,0l1.73,1.8l-8.42,8.35l21.73,0l0,2.6l-21.74,0l8.42,8.35l-1.73,1.8l-11.47,-11.45l11.48,-11.45Z" style="fill:%23fff;fill-rule:nonzero;" /></svg>')
      center / 18px 18px no-repeat !important;
  }
}
