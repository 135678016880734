@responsive {
  .columns-gap {
    column-gap: theme('spacing.10px');
  }

  .columns-2 {
    column-count: 2;
  }

  .columns-3 {
    column-count: 3;
  }
}

[class*='columns-'] {
  & > * {
    &:not(:only-child) {
      display: inline-block;
      width: 100%;
      margin: -1rem 0 2rem;
      break-inside: avoid;
      transform: translateY(1rem);

      @screen xl {
        margin: 0;
        transform: none;
      }
    }
  }
}
