.row {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
}

@responsive {
  .gutter-default {
    margin-inline: -0.3125rem;

    & .col,
    & .col-1,
    & .col-2,
    & .col-3,
    & .col-4,
    & .col-5,
    & .col-6,
    & .col-7,
    & .col-8,
    & .col-9,
    & .col-10,
    & .col-11,
    & .col-12,
    & .col-offset-0,
    & .col-offset-1,
    & .col-offset-2,
    & .col-offset-3,
    & .col-offset-4,
    & .col-offset-5,
    & .col-offset-6,
    & .col-offset-7,
    & .col-offset-8,
    & .col-offset-9,
    & .col-offset-10,
    & .col-offset-11,
    & .col-offset-12 {
      padding-inline: 0.3125rem;
    }
  }
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-offset-0,
.col-offset-1,
.col-offset-2,
.col-offset-3,
.col-offset-4,
.col-offset-5,
.col-offset-6,
.col-offset-7,
.col-offset-8,
.col-offset-9,
.col-offset-10,
.col-offset-11,
.col-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
}

@responsive {
  .col {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-1 {
    max-width: 8.33333333%;
    flex-basis: 8.33333333%;
  }

  .col-2 {
    max-width: 16.66666667%;
    flex-basis: 16.66666667%;
  }

  .col-3 {
    max-width: 25%;
    flex-basis: 25%;
  }

  .col-4 {
    max-width: 33.33333333%;
    flex-basis: 33.33333333%;
  }

  .col-5 {
    max-width: 41.66666667%;
    flex-basis: 41.66666667%;
  }

  .col-6 {
    max-width: 50%;
    flex-basis: 50%;
  }

  .col-7 {
    max-width: 58.33333333%;
    flex-basis: 58.33333333%;
  }

  .col-8 {
    max-width: 66.66666667%;
    flex-basis: 66.66666667%;
  }

  .col-9 {
    max-width: 75%;
    flex-basis: 75%;
  }

  .col-10 {
    max-width: 83.33333333%;
    flex-basis: 83.33333333%;
  }

  .col-11 {
    max-width: 91.66666667%;
    flex-basis: 91.66666667%;
  }

  .col-12 {
    max-width: 100%;
    flex-basis: 100%;
  }

  .col-offset-0 {
    margin-inline-start: 0;
  }

  .col-offset-1 {
    margin-inline-start: 8.33333333%;
  }

  .col-offset-2 {
    margin-inline-start: 16.66666667%;
  }

  .col-offset-3 {
    margin-inline-start: 25%;
  }

  .col-offset-4 {
    margin-inline-start: 33.33333333%;
  }

  .col-offset-5 {
    margin-inline-start: 41.66666667%;
  }

  .col-offset-6 {
    margin-inline-start: 50%;
  }

  .col-offset-7 {
    margin-inline-start: 58.33333333%;
  }

  .col-offset-8 {
    margin-inline-start: 66.66666667%;
  }

  .col-offset-9 {
    margin-inline-start: 75%;
  }

  .col-offset-10 {
    margin-inline-start: 83.33333333%;
  }

  .col-offset-11 {
    margin-inline-start: 91.66666667%;
  }
}
